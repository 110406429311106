import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// // Time Tracking
export const BRAND_NAME = "Time Tracking"; // App Name
export const SANDBOX = false;
const firebaseConfig = {
  apiKey: "AIzaSyD6KR4Bk7UX9Ulopw7mQIPTw04ef6VLiog",
  authDomain: "time-tracking-3256a.firebaseapp.com",
  databaseURL: "https://time-tracking-3256a.firebaseio.com",
  projectId: "time-tracking-3256a",
  storageBucket: "time-tracking-3256a.appspot.com",
  messagingSenderId: "494716350808",
  appId: "1:494716350808:web:c8a4c1f3805f2408cbb52d",
  measurementId: "G-D96TL7MNTW",
};

firebase.initializeApp(firebaseConfig);

export default firebase;

export const authApp = firebase.initializeApp(firebaseConfig, "authApp");

// ========================= App String ========================= //
export const MAP_API_KEY = "AIzaSyCu_IMhYlj7IuVoC2w_m9d2OR_BfYqMazw"; // Google map API
export const MAX_INPUT = 8; // Tối đa số lượt input in out
export const VERSION = "v0.1.14";
export const URL = {
  // url
  home: "/",
  signin: "/signin",
  branch: "/branch",
  reportManagement: "/report",
  reportTimeTracking: "/report_time_tracking",
  productManagement: "/product_managerment",
  contactsManagement: "/contacts_managerment",
  templateManagement: "/template_managerment",
};
