import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { DropzoneArea } from 'material-ui-dropzone'

class BranchLogoDialogAdd extends Component {

  // ====================== Inits ====================== //
    
    constructor(props) {
        super(props);
        // state
        this.state = {
            error: '',
            files: [],
        };
    }

    // ====================== Functions ====================== //

    handleUpload = (files) => {
      this.setState({
        files,
      });
    }

    handleUnUpload = () => {
      this.setState({
        files: [],
      });
      this.props.handleClose();
    }

    // ====================== Firebase Functions ====================== //

    

    // ====================== Render Component ====================== //
    // ====================== Render Main ====================== //

    render() {
        const { files } = this.state;
        const { dialogOpen, handleClose } = this.props;
        const disabled = files.length < 1 ? true : false;
        return (
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='sm'
                fullWidth
            >
            <DialogTitle id="scroll-dialog-title">Select logo for this branch</DialogTitle>
            <DialogContent dividers >
              <DropzoneArea
                onChange={this.handleUpload}
                acceptedFiles={['image/jpeg', 'image/png']}
                filesLimit={1}
                maxFileSize={6000000}
              />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} >
                    Cancel
                </Button>
                <Button 
                  type="submit"
                  variant="contained"
                  className='add-btn'
                  style={{ backgroundColor: disabled ? '#999' : 'green', color: '#fff' }}
                  disabled={disabled}
                  onClick={() => this.props.handleConfirm(files)}
                >
                  Add
                </Button>
            </DialogActions>
        </Dialog>
    );
  }
}

const mapDispatchToProps = {
}
  
export default compose(connect(null,mapDispatchToProps))(BranchLogoDialogAdd)
  