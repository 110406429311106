import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    CircularProgress
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase';
import { 
    firestoreGetBranchList,
    firestoreDeleteBranch
} from  '../../../store/actions/firebase.action';

class BranchDialogDelete extends Component {

  // ====================== Inits ====================== //
    
    constructor(props) {
        super(props);
        // state
        this.state = {
            error: '',
            loading: false,
        };
    }

    // ====================== Functions ====================== //

    // ====================== Firebase Functions ====================== //

    handleDeleteBranch = () => {
        const { branch } = this.props;
        this.setState({
            loading: true,
        }, () => {
          const userRef= firebase.firestore().collection('user');
          userRef.where('branch', '==', branch.id)
          .get()
          .then(snapshot => {
              if (snapshot.empty) { // Nếu không có user nào sử dụng branch này thì delete
                this.props.firestoreDeleteBranch(branch, () => {
                    this.props.firestoreGetBranchList(() => {
                        this.setState({
                            loading: false,
                        }, () => {
                            this.props.handleConfirm();
                        })
                    });
                });
              } else {
                  this.setState({ // Xử lý lỗi
                      loading: false,
                      error: `Cannot delete ${branch.tradingName} because it has been in used.`,
                  });
              }
          });
        })
    }

    // ====================== Render Component ====================== //


    renderDeleteButton = () =>{
        const { loading } = this.state;
        if (loading) {
          return (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className='add-btn'
              disabled
              startIcon={<CircularProgress size={15}/>}
            >
              Delete
            </Button>
          );
        }
        return (
          <Button 
            onClick={this.handleDeleteBranch} 
            type="submit"
            variant="contained"
            className='add-btn'
            style={{ backgroundColor: 'red', color: '#fff' }}
          >
            Delete
          </Button>
        );
    }

    
    // ====================== Render Main ====================== //

    render() {
        const { error, loading } = this.state;
        const { dialogOpen, handleClose, branch } = this.props;
        return (
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='sm'
                fullWidth
            >
            <DialogTitle id="scroll-dialog-title">Remove Branch {branch && branch.tradingName }</DialogTitle>
            <DialogContent dividers >
              <p>Are you sure to delete "{branch && branch.tradingName }". This action can't be undone.</p>
              <div className="red-text center">
                  { error ? <p>{error}</p> : null }
              </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={loading}>
                    Cancel
                </Button>
                {this.renderDeleteButton()}
            </DialogActions>
        </Dialog>
    );
  }
}

const mapDispatchToProps = {
    firestoreGetBranchList,
    firestoreDeleteBranch
}
  
export default compose(connect(null,mapDispatchToProps))(BranchDialogDelete)