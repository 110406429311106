import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    CircularProgress,
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';

import firebase from 'firebase';
import { 
  firestoreDeleteUserGroup,
  firestoreGetUserGroup
} from '../../../store/actions/firebase.action';

class UserGroupDialogDelete extends Component {

  // ====================== Inits ====================== //
    
    constructor(props) {
        super(props);
        // state
        this.state = {
            error: '',
            loading: false,
        };
    }

    // ====================== Functions ====================== //

    // ====================== Firebase Functions ====================== //

    firebaseDeleteUserGroup = () => {
      const { groupData } = this.props;
      if (groupData) {
        this.setState({
          loading: true,
        }, () => {
            const userRef= firebase.firestore().collection('user');
            userRef.where('group', '==', groupData.id)
            .get()
            .then(snapshot => {
                if (snapshot.empty) { // Nếu không có user nào sử dụng group này thì delete
                    this.props.firestoreDeleteUserGroup(groupData, () => {
                        this.props.firestoreGetUserGroup();
                        this.props.handleClose(); // close dialog
                    });
                } else {
                    this.setState({ // Xử lý lỗi
                        loading: false,
                        error: `Cannot delete ${groupData.name} group because it has been in used.`,
                    });
                }
            });
        });
      }
    }

    // ====================== Render Component ====================== //

    renderDeleteUserGroupButton = () =>{
        const { loading } = this.state;
        if (loading) {
          return (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className='add-btn'
              disabled
              startIcon={<CircularProgress size={15}/>}
            >
              Delete
            </Button>
          );
        }
        return (
          <Button 
            onClick={() => this.firebaseDeleteUserGroup()} 
            type="submit"
            variant="contained"
            className='add-btn'
            style={{ backgroundColor: 'red', color: '#fff' }}
          >
            Delete
          </Button>
        );
    }

    
    // ====================== Render Main ====================== //

    render() {
        const { error, loading } = this.state;
        const { dialogOpen, handleClose, groupData } = this.props;
        return (
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='sm'
                fullWidth
            >
            <DialogTitle id="scroll-dialog-title">Delete group {groupData && groupData.name }</DialogTitle>
            <DialogContent dividers >
              <p>Are you sure to delete "{groupData && groupData.name }". This action can't be undone.</p>
              <div className="red-text center">
                  { error ? <p>{error}</p> : null }
              </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={loading}>
                    Cancel
                </Button>
                {this.renderDeleteUserGroupButton()}
            </DialogActions>
        </Dialog>
    );
  }
}

const mapDispatchToProps = {
  firestoreDeleteUserGroup,
  firestoreGetUserGroup
}
  
export default compose(connect(null,mapDispatchToProps))(UserGroupDialogDelete)
  