import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    CircularProgress,
    Grid,
    FormControl,
    InputLabel,
    FormHelperText
} from '@material-ui/core';
import {
    AccessTime
} from '@material-ui/icons';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { firestoreTimeTrackingHandleTasks } from '../../../store/actions/firebase.action';
import { MAX_INPUT } from '../../../config/config';


class TimeTrackingDialogEditSelected extends Component {

  // ====================== Inits ====================== //
    
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: false,
            error: Array(MAX_INPUT).fill(false),
            data: Array(MAX_INPUT).fill(null),
            maxInput: 0,
        };
    }

    resetDataAndClose = () => {
        this.setState({
            loading: false,
            error: Array(MAX_INPUT).fill(false),
            data: Array(MAX_INPUT).fill(null),
            maxInput: 0,
        }, () => {
            this.props.handleClose();
        });
    }

    resetDataAndConfirm = () => {
        this.setState({ 
            loading: false,
            error: Array(MAX_INPUT).fill(false),
            data: Array(MAX_INPUT).fill(null),
            maxInput: 0,
        }, () => {
            this.props.handleUpdateComplete();
        })
    }


    // ====================== Functions ====================== //

    handleValidate = (slot) => {
        const { selected } = this.props;
        const { data, maxInput } = this.state;
        // round 01: xử lý dữ liệu input thô
        let error = Array(MAX_INPUT).fill(false);
        error = data && data.map((item, index) => {
            if (item && data[index - 1] && data[index - 1] > item) { // Nếu tồn tại giá trị trước && giá trị đó lớn hơn
                return true;
            } else if (item && data[index + 1] && data[index + 1] < item) { // Nếu tồn tại giá trị sau và giá trị sau nhỏ hơn
                return  true;
            }
            return false;
        });
        // round 02: xử lý dữ liệu input lần 2 theo slot này
        const thisSlot = data[slot];
        const thisTime = moment(thisSlot).diff(moment().startOf('day'), 'seconds');
        selected.forEach((item) => {
            if ( slot > 0 ) {
                const data = item.data[slot-1];
                const time = data && data.time;
                if (thisTime <= time){
                    error[slot] = true;
                }
            }
            if (slot < maxInput) {
                const data = item.data[slot + 1 ];
                const time = data && data.time;
                if (thisTime >= time){
                    error[slot] = true;
                }
            }
        });
        this.setState({
            error,
        });
    }

    handleTimeChange = (value, index) => {
        const { data } = this.state;
        const newData = [...data];
        newData[index] = value;
        this.setState({
            data: newData,
        }, () => {
            this.handleValidate(index);
        });
    }

    getMaxRange = () => {
        const { selected } = this.props;
        const maxInput = Math.min.apply(Math, selected.map(s => s.length));
        this.setState({
            maxInput,
        })
    }

    // ====================== Firebase Functions ====================== //


    updateRecord = () => {
        const { data } = this.state;
        const { selected } = this.props;
        // Tạo task
        let tasks = [];
        selected && selected.forEach(item => {
            const itemData = item && item.data;
            const user = item && item.user;
            const stringDate = item && item.stringDate;
            data && data.forEach((change, index) => {
                if (change) { // không null tức là update tại index này
                    const time = moment(change).format('hh:mm A');
                    const checkedAt = moment(`${stringDate} ${time}`, 'DD/MM/YYYY hh:mm A')._d;
                    const recordId = itemData[index].recordId;
                    const newTask = {
                        checkedAt,
                        type: 'update',
                        user,
                        recordId,
                    }
                    tasks = [...tasks, newTask];
                }
            });
        });
        // Thực hiện task
        this.setState({
            loading: true,
        }, () => {
            this.props.firestoreTimeTrackingHandleTasks({ tasks } , () => {
                this.resetDataAndConfirm();
            });
        });
    }
    
    // ====================== Render Component ====================== //


    renderTimePicker = () => {
        const { time, date, selectedUser } = this.state;
        const disabled = !(date && selectedUser);
        return (
            <FormControl fullWidth>
                <InputLabel 
                    htmlFor="time-picker-scroll"
                    className='time-picker-custom-input-label'
                >Time</InputLabel>
                <TimePicker
                    id='time-picker-scroll'
                    showSecond={false}
                    disabled={disabled}
                    defaultValue={time}
                    className='time-picker-scroll'
                    format='hh:mm A'
                    onChange={this.handleTimeChange}
                    use12Hours
                    inputReadOnly
                    inputIcon={<AccessTime style={{ color: 'rgba(0, 0, 0, 0.54)' }}/>}
                    clearIcon={null}
                    minuteStep={5}
                />
            </FormControl>
            
        );
    }

    renderConfirmButton = () => {
        const { loading, data, error } = this.state;
        const length = data.filter(d => d).length;
        const errorLength = error.filter(e => e).length;
        const disabled = loading || length === 0 || errorLength > 0;
        if (loading) {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    disabled
                    startIcon={<CircularProgress size={15}/>}
                    style={{ marginRight: 5 }}
                >
                    OK
                </Button>
            ); 
        }
        return (
            <Button
                variant="contained"
                color="primary"
                disabled={disabled}
                onClick={() => this.updateRecord()}
                style={{ marginRight: 5 }}
            >
                OK
            </Button>
        );
    }

    renderGridBlock = () => {
        const { data } = this.state;
        return (
            <Grid 
                container 
                spacing={3} 
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ marginTop: 20, marginBottom: 20 }}
            >
                { data && data.map((d, i) => this.renderGridItem(d, i)) }
            </Grid>
        );
    }

    renderGridItem = (row, index) => {
        const { error, maxInput } = this.state;
        const number = Math.round(index / 2) + (index % 2 === 0 ? 1 : 0);
        const label = index % 2 === 0 ? `In ${number}` : `Out ${number}`;
        const defaultOpenValue = moment().hours(0).minute(0).second(0);
        const color = error[index] ? 'red' : '#333';
        if (index < maxInput) {
            return (
                <Grid item lg={3} md={3} sm={3} xs={6} key={index}>
                    <FormControl fullWidth>
                        <InputLabel 
                            htmlFor="time-picker-scroll"
                            className='time-picker-custom-input-label'
                            style={{ textTransform: 'uppercase', color }}
                        >
                            {label}
                        </InputLabel>
                        <TimePicker
                            picker="time"
                            showSecond={false}
                            value={row}
                            className={`time-picker-scroll primary ${error[index] ? 'error' : ''}`}
                            defaultOpenValue={defaultOpenValue}
                            format='hh:mm A'
                            onChange={(value) => this.handleTimeChange(value, index)}
                            use12Hours
                            inputReadOnly
                            inputIcon={<AccessTime style={{ color: 'rgba(0, 0, 0, 0.54)' }}/>}
                            minuteStep={5}
                        />
                    </FormControl>
                    <FormHelperText 
                        error={error[index]}
                        style={{ position: 'absolute' }}
                    >{ error[index] ? 'Invalid Time' : '' }</FormHelperText>
                </Grid>
            );
        }
        return (
            <Grid item lg={3} md={3} sm={3} xs={6} key={index}>
                <Button 
                    variant='contained'
                    color="primary" 
                    fullWidth 
                    className='time-block'
                    disabled
                    style={{ 
                        height: 40,
                        marginTop: 8,
                        marginBottom: 4,
                    }}
                    disableElevation
                >
                    {label}
                </Button>
            </Grid>
        );
    }


    // ====================== Render Main ====================== //

    render() {
        const { loading } = this.state;
        const { dialogOpen, selected } = this.props;
        return (
            <Dialog
                open={dialogOpen}
                onEnter={() => this.getMaxRange()}
                onClose={() => this.resetDataAndClose()}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='md'
                fullWidth
                disableBackdropClick
            >
                <DialogTitle id="scroll-dialog-title">Edit Selected Records</DialogTitle>
                <DialogContent dividers >
                    <p>Total selected records: {selected && selected.length}</p>
                    { this.renderGridBlock() }
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => this.resetDataAndClose()}
                        style={{ marginRight: 5 }}
                        disabled={loading}
                    >
                        Close
                    </Button>
                    { this.renderConfirmButton() }
                </DialogActions>
            </Dialog>
    );
  }
}

const mapDispatchToProps = {
    firestoreTimeTrackingHandleTasks
}
  
export default compose(connect(null,mapDispatchToProps))(TimeTrackingDialogEditSelected)
  