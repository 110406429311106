import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';

class BranchLogoDialogDelete extends Component {

  // ====================== Inits ====================== //
    
    constructor(props) {
        super(props);
        // state
        this.state = {
            error: '',
            loading: false,
        };
    }

    // ====================== Functions ====================== //

    // ====================== Firebase Functions ====================== //

    // ====================== Render Component ====================== //

    renderDeleteLogoButton = () =>{
        return (
          <Button 
            onClick={this.props.handleConfirm} 
            type="submit"
            variant="contained"
            className='add-btn'
            style={{ backgroundColor: 'red', color: '#fff' }}
          >
            Remove
          </Button>
        );
    }

    
    // ====================== Render Main ====================== //

    render() {
        const { error, loading } = this.state;
        const { dialogOpen, handleClose, branch } = this.props;
        return (
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='sm'
                fullWidth
            >
            <DialogTitle id="scroll-dialog-title">Remove Branch {branch && branch.tradingName } Logo</DialogTitle>
            <DialogContent dividers >
              <p>Are you sure to remove this logo.</p>
              <div className="red-text center">
                  { error ? <p>{error}</p> : null }
              </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={loading}>
                    Cancel
                </Button>
                {this.renderDeleteLogoButton()}
            </DialogActions>
        </Dialog>
    );
  }
}

const mapDispatchToProps = {
}
  
export default compose(connect(null,mapDispatchToProps))(BranchLogoDialogDelete)