import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    InputAdornment,
    TextField,
    CircularProgress,
    Grid,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import {
    SearchOutlined,
    AddOutlined,
    DeleteOutline,
    AccessTime
} from '@material-ui/icons';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import MomentUtils from '@date-io/moment';
import { Autocomplete } from '@material-ui/lab';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { 
    firestoreGetTimeTrackingByUser,
    firestoreTimeTracking
} from  '../../../store/actions/firebase.action';
import { MAX_INPUT } from '../../../config/config';
import TimeTrackingDialogDelete from './time_tracking_delete';

class TimeTrackingDialogAdd extends Component {

  // ====================== Inits ====================== //
    
    constructor(props) {
        super(props);
        // state
        this.state = {
            error: '',
            selectedUser: null,
            date: null,
            time: null,
            minTime: null,
            data: Array(MAX_INPUT).fill(null),
            dialogDelete: false,
            selectedTimeTracking: null,
        };
    }

    defaultData = () => {
        this.setState({
            error: '',
            selectedUser: null,
            date: null,
            time: null,
            minTime: null,
            data: Array(MAX_INPUT).fill(null),
            dialogDelete: false,
            selectedTimeTracking: null,
        });
    }

    resetData = () => {
        this.setState({
            error: '',
            selectedUser: null,
            date: null,
            time: null,
            minTime: null,
            data: Array(MAX_INPUT).fill(null),
            dialogDelete: false,
            selectedTimeTracking: null,
        }, () => {
            this.props.handleClose();
        });
    }

    // ====================== Functions ====================== //

    selectUser = (selectedUser) => {
        this.setState({
            selectedUser,
        }, () => {
            this.getUserData()
        });
    }

    handleDateChange = (date) => {
        this.setState({
            date,
        }, () => {
            this.getUserData()
        });
    }

    handleTimeChange = (value) => {
        const { date } = this.state;
        const string = moment(value).format('hh:mm A');
        const day = moment(date).format('DD/MM/YYYY');
        const time = moment(`${day} ${string}`, 'DD/MM/YYYY hh:mm A');
        this.setState({
            time,
        });
    }

    openDialogDelete = (selectedTimeTracking) => {
        this.setState({
            dialogDelete: true,
            selectedTimeTracking,
        });
    }
    
    closeDialogDelete = () => {
        this.setState({
            dialogDelete: false,
            selectedTimeTracking: null,
        });
    }

    confirmDialogDelete = () => {
        this.setState({
            dialogDelete: false,
            selectedTimeTracking: null,
        }, () => {
            this.getUserData();
        });
    }

    // ====================== Firebase Functions ====================== //

    getUserData = () => {
        const { selectedUser, date } = this.state;
        if (selectedUser && date) {
            this.setState({
                loading: true,
            }, () => {
                this.props.firestoreGetTimeTrackingByUser({ selectedUser, date }, (response) => {
                    let data = response;
                    let minTime = null;
                    if (response.length > 0) {
                        minTime = moment(response[response.length - 1].checkedAt.toDate());
                    }
                    for (let i = 0; i < (MAX_INPUT - response.length); i++) {
                        data = [...data, null];
                    }
                    this.setState({
                        loading: false,
                        data,
                        time: null,
                        minTime,
                    })
                });
                
            });
        }
    }

    addNewRecord = () => {
        const { selectedUser, time } = this.state;
        if (selectedUser && time) {
            this.setState({
                loading: true,
            }, () => {
                const checkedAt = moment(time)._d;
                const branch = selectedUser && selectedUser.branch;
                const user = selectedUser && selectedUser.id;
                const owner = selectedUser && selectedUser.owner;
                const data = {
                    checkedAt,
                    user,
                    owner,
                    branch,
                    screenshot:'',
                    subUrl:'admin',
                    longitude:'',
                    latitude:'',
                    deviceName: 'admin',
                };
                this.props.firestoreTimeTracking({ data }, () => {
                    this.setState({
                        loading: false,
                    }, () => {
                        this.getUserData();
                    });
                });
            });
        }
    }

    
    // ====================== Render Component ====================== //

    renderTop = () => {
        return (
            <Grid 
                container 
                spacing={3} 
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <Grid item lg={4} md={4} sm={6} xs={6}>
                    { this.renderSearchAutocomplete() }
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                    {this.renderDatePicker()}
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                    {this.renderTimePicker()}
                </Grid>
                <Grid item lg={2} md={2} sm={6} xs={6}>
                    {this.renderAddButton()}
                </Grid>
            </Grid>
        );
    }

    renderSearchAutocomplete = () => {
        const { users, branch } = this.props;
        const { selectedUser } = this.state;
        let options = users || [];
        options = options.filter(o => o.branch === branch);
        return (
            <Autocomplete
                id="user-search"
                onChange={(e, value) => this.selectUser(value)}
                value={selectedUser}
                getOptionSelected={(option) => option}
                getOptionDisabled={(option) => !option.active}
                getOptionLabel={(option) => {
                    const firstName = option.firstName;
                    const lastName = option.lastName;
                    const fullName = firstName && lastName && `${firstName} ${lastName}`;
                    return `${fullName} (${option.username})`;
                }}
                options={options}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    margin='dense' 
                    label="Search By Name"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined />
                            </InputAdornment>
                        ),
                    }}
                    />
                )}
            />
        );
    }

    renderDatePicker = () => {
        const { date } = this.state;
        return (
            <MuiPickersUtilsProvider utils={MomentUtils} >
                <KeyboardDatePicker
                    autoOk
                    className='date-picker'
                    variant="inline"
                    inputVariant="outlined"
                    fullWidth
                    value={date}
                    label="Date"
                    format="DD/MM/YYYY"
                    InputAdornmentProps={{ position: "end" }}
                    onChange={date => this.handleDateChange(date)}
                    margin='dense'
                />
            </MuiPickersUtilsProvider>  
        );
    }

    renderTimePicker = () => {
        const { time, date, selectedUser } = this.state;
        const disabled = !(date && selectedUser);
        const defaultOpenValue = moment().hours(0).minute(0).second(0);
        return (
            <FormControl fullWidth>
                <InputLabel 
                    htmlFor="time-picker-scroll"
                    className='time-picker-custom-input-label'
                >Time</InputLabel>
                <TimePicker
                    picker="time"
                    id='time-picker-scroll'
                    showSecond={false}
                    disabled={disabled}
                    value={time}
                    className='time-picker-scroll'
                    format='hh:mm A'
                    defaultOpenValue={defaultOpenValue}
                    onChange={this.handleTimeChange}
                    use12Hours
                    inputReadOnly
                    inputIcon={<AccessTime style={{ color: 'rgba(0, 0, 0, 0.54)' }}/>}
                    clearIcon={null}
                    minuteStep={5}
                />
            </FormControl>
        );
    }

    renderAddButton = () => {
        const { loading, date, selectedUser, time, minTime, data } = this.state;
        const validTime = time > minTime;
        const isMax = data.filter(d => d).length >= MAX_INPUT;
        const disabled = !(selectedUser && date && validTime && !loading && !isMax);
        if (loading) {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    disabled
                    fullWidth
                    style={{ marginTop: 3, backgroundColor: disabled ? '#e0e0e0' : 'green' }}
                    startIcon={<CircularProgress size={15}/>}
                >
                    Add
                </Button>
            ); 
        }
        return (
            <Button
                variant="contained"
                color="primary"
                disabled={ disabled }
                fullWidth
                style={{ marginTop: 3 }}
                startIcon={<AddOutlined />}
                onClick={() => this.addNewRecord()}
            >
                Add
            </Button>
        );
    }

    renderGridBlock = () => {
        const { data, time, minTime } = this.state;
        const validTime = time > minTime;
        const length = data.filter(d => d).length;
        const hightLight = validTime && length;
        const deleteIndex = length && (length - 1);
        return (
            <Grid 
                container 
                spacing={3} 
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ marginTop: 20, marginBottom: 20 }}
            >
                { data && data.map((d, i) => this.renderGridItem(d, i, hightLight, deleteIndex)) }
            </Grid>
        );
    }

    renderGridItem = (row, index, hightLight, deleteIndex) => {
        if (row && row.checkedAt) {
            const checkedAt = row.checkedAt.toDate();
            const time = moment(checkedAt).format('hh:mm A');
            if (index === deleteIndex) { // Có thể delete
                return (
                    <Grid item lg={3} md={3} sm={3} xs={6} key={index}>
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            fullWidth 
                            className='time-block deleteable' 
                            disableElevation
                            endIcon={<DeleteOutline style={{ color: 'red' }}/>}
                            onClick={() => this.openDialogDelete(row)}
                        >
                            {time}
                        </Button>
                    </Grid>
                );
            }
            return (
                <Grid item lg={3} md={3} sm={3} xs={6} key={index}>
                    <Button 
                        variant="outlined" 
                        color="primary" 
                        fullWidth 
                        className='time-block' 
                        disableElevation
                    >
                        {time}
                    </Button>
                </Grid>
            );
        }
        const number = Math.round(index / 2) + (index % 2 === 0 ? 1 : 0);
        const label = index % 2 === 0 ? `In ${number}` : `Out ${number}`;
        return (
            <Grid item lg={3} md={3} sm={3} xs={6} key={index}>
                <Button 
                    variant={ hightLight === index ? 'outlined' : 'contained' } 
                    color="primary" 
                    fullWidth 
                    className='time-block' 
                    style={{ 
                        borderColor: hightLight === index ? 'green' : '#e0e0e0',
                        color: hightLight === index ? 'green' : '#999',
                    }}
                    disabled={hightLight === index ? false : true}
                    disableElevation
                >
                    {label}
                </Button>
            </Grid>
        );
    }

    // ====================== Render Main ====================== //

    render() {
        const { dialogOpen } = this.props;
        const { dialogDelete, selectedTimeTracking, selectedUser } = this.state;
        return (
            <Dialog
                open={dialogOpen}
                onEnter={() => this.defaultData()}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='md'
                fullWidth
                disableBackdropClick
            >
                <DialogTitle id="scroll-dialog-title">Add new record</DialogTitle>
                <DialogContent dividers >
                { this.renderTop() }
                { this.renderGridBlock() }
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => this.resetData()}
                        style={{ marginRight: 20 }}
                    >
                        Close
                    </Button>
                </DialogActions>
                <TimeTrackingDialogDelete 
                    dialogOpen={dialogDelete}
                    timeTracking={selectedTimeTracking}
                    selectedUser={selectedUser}
                    handleClose={this.closeDialogDelete}
                    handleConfirm={this.confirmDialogDelete}
                />
            </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
      return {
        users: state.firebaseWeb.users,
      }
}

const mapDispatchToProps = {
    firestoreGetTimeTrackingByUser,
    firestoreTimeTracking
}
  
export default compose(connect(mapStateToProps,mapDispatchToProps))(TimeTrackingDialogAdd)
  