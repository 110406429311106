import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import SignInScene from "../01_Login/login";
import UserManagementScene from "../02_User_Management/user_management";
import BranchManagementScene from "../03_Branch_Management/branch_management";
import ReportManagementScene from "../04_Report_Management/report_management";
import ReportTimeTrackingScene from "../05_Report_Time_Tracking/time_tracking_management";
// import ProductsServiceScene from '../06_Product_Services/product_management';
// import ContactsScene from '../07_Contacts_Management/contacts_management';
// import TemplateScene from '../08_Template_Management/template_management';

import { URL } from "../../config/config";

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path={URL.home} component={UserManagementScene} exact />
          <Route path={URL.signin} component={SignInScene} />
          <Route path={URL.branch} component={BranchManagementScene} />
          <Route path={URL.reportManagement} component={ReportManagementScene} />
          <Route path={URL.reportTimeTracking} component={ReportTimeTrackingScene} />
          {/* <Route path={URL.productManagement} component={ProductsServiceScene} />
              <Route path={URL.contactsManagement} component={ContactsScene} />
              <Route path={URL.templateManagement} component={TemplateScene} /> */}
        </Switch>
      </BrowserRouter>
    );
  }
}

export default connect(null, {})(Router);
