import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase';
import { 
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    TextField,
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
} from '@material-ui/core';
import { Delete, PhotoCamera } from '@material-ui/icons';
import Resizer from 'react-image-file-resizer';
import { 
    firestoreAddBranch,
    firestoreUpdateBranch,
    firestoreGetBranchList,
    firestoreUploadBranchLogo
} from  '../../store/actions/firebase.action';
import FacebookImg from '../../asset/icon/facebook.png';
import InstagramImg from '../../asset/icon/instagram.png';
import TwitterImg from '../../asset/icon/twitter.png';
import LocationAutoComplete from '../../component/location_autocomplete';
import BranchLogoDialogDelete from './dialog/branch_logo_dialog_delete';
import BranchLogoDialogAdd from './dialog/branch_logo_dialog_add';
import BranchDialogDelete from './dialog/branch_dialog_delete';
import placeholder from '../../asset/img/placeholder.png'

class BranchDetail extends Component {

// ====================== Inits ====================== //

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            mode: 'none', // 'add', 'select', 'update', 'none'
            branch: {
                id: null,
                facebook: '',
                instagram: '',
                twitter: '',
                logoUrl: '',
                registeredCompanyName: '',
                companyNumber: '',
                tradingName: '',
                website: '',
                contactEmail: '',
                contactPhone: '',
                serviceLocation: '',
                businessSummary: '',
                username: '',
                password: '',
            },
            error: {
                facebook: '',
                instagram: '',
                twitter: '',
                registeredCompanyName: '',
                companyNumber: '',
                tradingName: '',
                website: '',
                contactEmail: '',
                contactPhone: '',
                serviceLocation: '',
                businessSummary: '',
                username: '',
                password: '',
            },
            require: {
                registeredCompanyName: true,
                tradingName: true,
                contactEmail: true,
                contactPhone: true,
                serviceLocation: true,
            },
            regex: {
                facebook: /^(http|https):\/\/[^ "]+$/, // Url
                instagram: /^(http|https):\/\/[^ "]+$/, // Url
                twitter: /^(http|https):\/\/[^ "]+$/, // Url
                registeredCompanyName: /^[a-zA-Z0-9\s]{1,150}$/, // 150 không kí tự
                companyNumber: /^[0-9]{1,15}$/, // 15 chỉ số
                tradingName: /^[a-zA-Z0-9\s]{1,150}$/, // 150 không kí tự
                website: /^(http|https):\/\/[^ "]+$/, // Url
                // eslint-disable-next-line no-useless-escape
                contactEmail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // Email format
                contactPhone: /^[0-9.+\-,()\s]{9,11}$/, // 9 tới 11 số , kèm . + - ()
                businessSummary: /^.{1,300}$/,
            },
            inputRegex: {
                registeredCompanyName: /^[a-zA-Z0-9\s]{0,150}$/, // 150 không kí tự
                companyNumber: /^[0-9]{0,15}$/, // 15 chỉ số
                tradingName: /^[a-zA-Z0-9\s]{0,150}$/, // 150 không kí tự
                contactPhone: /^[0-9.+\-,()\s]{0,11}$/, // 9 tới 11 số , kèm . + - ()
                businessSummary: /^.{0,300}$/, // 300
            },
            errorMess: {
                facebook: 'Invalid Url.',
                instagram: 'Invalid Url.',
                twitter: 'Invalid Url.',
                registeredCompanyName: 'Invalid Company Name. Must contain 1-150 characters, numbers and alphabets.',
                companyNumber: 'Invalid Company Number. Must contain 1-15 characters, numbers only.',
                tradingName: 'Invalid Trading Name. Must contain 1-150 characters, numbers and alphabets.',
                website: 'Invalid Website Url.',
                contactEmail: 'Invalid Email Addresss.',
                contactPhone: 'Invalid Phone Number.',
                businessSummary: 'Maximum 300 characters',
            },
            logoDialogDelete: false,
            logoDialogAdd: false,
            branchDialogDelete: false,
        };
        
    }

    resetDefaultData = (mode, data) => {
        this.setState({
            loading: false,
            showPassword: false,
            mode: mode || 'none',
            branch: data ? data : {
                id: null,
                facebook: '',
                instagram: '',
                twitter: '',
                logoUrl: '',
                registeredCompanyName: '',
                companyNumber: '',
                tradingName: '',
                website: '',
                contactEmail: '',
                contactPhone: '',
                serviceLocation: '',
                businessSummary: '',
            },
            error: {
                facebook: '',
                instagram: '',
                twitter: '',
                logoUrl: '',
                registeredCompanyName: '',
                companyNumber: '',
                tradingName: '',
                website: '',
                contactEmail: '',
                contactPhone: '',
                serviceLocation: '',
                businessSummary: '',
            },
        });
    }

    UNSAFE_componentWillReceiveProps = (nextProps) =>{
        if (nextProps.branch) {
            if (nextProps.branch.id){ // có id tức là data cũ
                this.resetDefaultData('select', nextProps.branch);
            } else { // ko có id tức là mới
                this.resetDefaultData('add');
            }
        }
    }

    // ====================== Functions ====================== //
    
    // ====================== Hàm xử lý dialog ====================== //
    openLogoDelete = () => {
        this.setState({
            logoDialogDelete: true
        });
    }
    closeLogoDelete = () => {
        this.setState({
            logoDialogDelete: false
        });
    }
    confirmDelete = () => {
        const { branch } = this.state;
        this.setState({
            logoDialogDelete: false,
            branch: {
                ...branch,
                logoUrl: '',
            }
        });
    }
    openLogoAdd = () => {
        const { mode } = this.state;
        const disabled = mode === 'none' || mode === 'select';
        if (disabled) {
            return;
        }
        this.setState({
            logoDialogAdd: true
        })
    }
    closeLogoAdd = () => {
        this.setState({
            logoDialogAdd: false
        });
    }
    confirmAdd = (files) => {
        const { branch } = this.state;
        this.setState({
            logoDialogAdd: false
        }, () => {
            Resizer.imageFileResizer(
                files[0],
                512,
                512,
                'jpg',
                96,
                0,
                uri => {
                    this.setState({
                        branch: {
                            ...branch,
                            logoUrl: uri
                        }
                    });
                },
                'base64'
            );
            ;
        });
    }
    openBranchDelete = () => {
        this.setState({
            branchDialogDelete: true,
        })
    }
    closeBranchDelete = () => {
        this.setState({
            branchDialogDelete: false,
        })
    }
    confirmBranchDelete = () => {
        this.setState({
            branchDialogDelete: false,
        }, () => {
            this.resetDefaultData();
        });
    }
    // ====================== Hàm xử lý validation ====================== //

    handleValidate = ({id, value}) => { // xử lý validate
        if (id === 'id') {return;}
        const { require, regex, errorMess } = this.state;
        const condition = regex[id];
        const errorString = errorMess[id];
        // Xử lý validate
        if (value && value.length > 0) {
            if (condition && condition.test(value)) { // valid
                return false;
            } else {// lỗi
                return errorString;
            }
        } else {
            if (require[id]) { // require
                return 'Please fill out this field';
            } else { // empty
                return false;
            }
        }
    }

    // ====================== hàm khác ====================== //

    handleChange = (e) => {
        const { branch, error, inputRegex } = this.state;
        const id = e.target.id;
        const value = e.target.value;
        const inputCondition = inputRegex && inputRegex[id];
        if (!inputCondition || (inputCondition && inputCondition.test(value))){
            this.setState({
                branch: {
                    ...branch,
                    [id]: value,
                },
                error: {
                    ...error,
                    [id]: this.handleValidate({id,value}),
                }
            });
        }
    }

    handleChangeLocation = (value) => {
        const { branch } = this.state;
        this.setState({
            branch: {
                ...branch,
                serviceLocation: value,
            },
        });
    }

    handleAddOrUpdateBranch = () => {
        const { branch } = this.state;
        let error = {};
        let allValid = true;
        Object.entries(branch).forEach((data, index) => {
            const id = data[0];
            const value = data[1];
            if (id === 'id') { return }
            const isError = this.handleValidate({ id, value });
            error = {
                ...error,
                [id]: isError,
            }
            if (isError) {
                allValid = false;
            }
        });
        if (!allValid) { // Ko hợp lệ tất cả các field
            this.setState({
                error
            });
        } else { // Hợp lệ
            if (branch.id) { // update
                this.firestoreUpdateBranch();
            } else { // add
                this.firestoreAddBranch();
            }
        }
    }

    handleEditBranch = () => {
        this.setState({
            mode: 'edit',
        });
    }


    // ====================== Firebase Functions ====================== //

    firestoreAddBranch = () => {
        this.setState({
            loading: true,
        }, () => {
            const { branch, error } = this.state;
            const tradingName = branch && branch.tradingName;
            const owner = firebase.auth().currentUser.uid;
            const branchRef = firebase.firestore().collection('branch');
            branchRef
            .where('tradingName', '==', tradingName)
            .where('owner', '==', owner)
            .get()
            .then(snapshot => {
                if (snapshot.empty) { // Nếu không trùng brand name
                    this.props.firestoreUploadBranchLogo(branch, (imgUrl) => {
                        const newData = {
                            ...branch,
                            logoUrl: imgUrl
                        }
                        this.props.firestoreAddBranch(newData, (data) => {
                            this.props.firestoreGetBranchList(() => {
                                this.setState({
                                    loading: false,
                                    branch: data,
                                })
                            });
                        });
                    });
                } else {
                    this.setState({ // Xử lý lỗi trùng brand name
                        loading: false,
                        error: {
                            ...error,
                            tradingName: 'This branch name has been created. Please use other name.'
                        },
                    });
                }
            });
        });
    }

    firestoreUpdateBranch = () => {
        this.setState({
            loading: true,
        }, () => {
            const { branch, error } = this.state;
            const tradingName = branch && branch.tradingName;
            const owner = firebase.auth().currentUser.uid;
            const branchRef = firebase.firestore().collection('branch');
            branchRef
            .where('tradingName', '==', tradingName)
            .where('owner', '==', owner)
            .get()
            .then(snapshot => {
                if (snapshot.empty) { // Nếu không trùng brand name
                    this.props.firestoreUploadBranchLogo(branch, (imgUrl) => {
                        const newData = {
                            ...branch,
                            logoUrl: imgUrl
                        }
                        this.props.firestoreUpdateBranch(newData, (data) => {
                            this.props.firestoreGetBranchList(() => {
                                this.setState({
                                    loading: false,
                                    branch: data,
                                })
                            });
                        });
                    });
                } else {// Xử lý lỗi trùng brand name
                    const dataList = snapshot.docs.map(doc => doc.data());
                    if (dataList.length === 1 && dataList[0].id === branch.id){ // trùng nhưng là tên cũ? tức là tự update
                        this.props.firestoreUploadBranchLogo(branch, (imgUrl) => {
                            const newData = {
                                ...branch,
                                logoUrl: imgUrl
                            }
                            this.props.firestoreUpdateBranch(newData, (data) => {
                                this.props.firestoreGetBranchList(() => {
                                    this.setState({
                                        loading: false,
                                        branch: data,
                                    })
                                });
                            });
                        });
                    } else {
                        this.setState({ 
                            loading: false,
                            error: {
                                ...error,
                                tradingName: 'This branch name has been created. Please use other name.'
                            },
                        });
                    }
                }
            });
        });
    }

    // ====================== Render Component ====================== //

    // Phần logo của branch detail
    renderLogo = () => {
        const { branch, mode } = this.state;
        const logoUrl = branch && branch.logoUrl;
        const disabled = mode === 'none' || mode === 'select';
        return (
            <div className='logo-container'>
                <img 
                    src={logoUrl || placeholder} 
                    className='branch-logo' 
                    alt='upload-logo' 
                    onClick={this.openLogoAdd}
                />
                <IconButton 
                    color="primary" 
                    aria-label="upload-picture" 
                    component="span"
                    className="upload-picture"
                    disabled={disabled}
                    onClick={this.openLogoAdd}
                >
                    <PhotoCamera />
                </IconButton>
                <IconButton 
                    color="secondary" 
                    aria-label="delete-picture" 
                    component="span"
                    className="delete-picture"
                    disabled={disabled || !logoUrl}
                    onClick={this.openLogoDelete}
                >
                    <Delete />
                </IconButton>
            </div>
        );
    }

    // Phần social của branch detail
    renderSocialsList = () => {
        const { branch, error, mode } = this.state;
        const fbError = error && error.facebook;
        const instaError = error && error.instagram;
        const twError = error && error.twitter;
        const disabled = mode === 'none' || mode === 'select';
        return (
            <List dense disablePadding className='no-padding-list'>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar alt="Facebook" src={FacebookImg}/>
                    </ListItemAvatar>
                    <TextField
                        variant="outlined"
                        margin='dense'
                        fullWidth
                        id="facebook"
                        label="Facebook"
                        name="facebook"
                        type="text"
                        autoFocus
                        disabled={disabled}
                        error={!!fbError}
                        helperText={fbError}
                        value={branch && branch.facebook}
                        onChange={this.handleChange}
                    />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar alt="Instagram" src={InstagramImg}/>
                    </ListItemAvatar>
                    <TextField
                        variant="outlined"
                        margin='dense'
                        fullWidth
                        id="instagram"
                        label="Instagram"
                        name="instagram"
                        type='text'
                        autoFocus
                        disabled={disabled}
                        error={!!instaError}
                        helperText={instaError}
                        value={branch && branch.instagram}
                        onChange={this.handleChange}
                    />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar alt="Twitter" src={TwitterImg}/>
                    </ListItemAvatar>
                    <TextField
                        variant="outlined"
                        margin='dense'
                        fullWidth
                        id="twitter"
                        label="Twitter"
                        name="twitter"
                        type="text"
                        autoFocus
                        disabled={disabled}
                        error={!!twError}
                        helperText={twError}
                        value={branch && branch.twitter}
                        onChange={this.handleChange}
                    />
                </ListItem>
            </List>
        );
    }

    renderCancelButton = () => {
        const { loading } = this.state;
        return (
            <Button 
                variant="contained" 
                color='default'
                style={{ float: 'left' }}
                disabled={loading}
                onClick={this.resetDefaultData}
            >
                Cancel
             </Button>
        );
    }

    renderSaveButton = () => {
        const { loading } = this.state;
        if (loading){
            return (
                <Button 
                    variant="contained" 
                    color='default'
                    disabled
                    style={{ float: 'right' }}
                    startIcon={<CircularProgress size={15}/>}
                >
                    Save
                </Button>
            );
        }
        return (
            <Button 
                variant="contained" 
                color='primary'
                style={{ backgroundColor: 'green', float: 'right' }}
                onClick={this.handleAddOrUpdateBranch}
            >
                Save
             </Button>
        );
    }

    renderDeleteButton = () => {
        const { loading } = this.state;
        return (
            <Button 
                variant="contained" 
                color='secondary'
                style={{ backgroundColor: 'red', float: 'left' }}
                disabled={loading}
                onClick={this.openBranchDelete}
            >
                Delete
             </Button>
        );
    }

    renderUpdateButton = () => {
        const { loading } = this.state;
        if (loading){
            return (
                <Button 
                    variant="contained" 
                    color='default'
                    disabled
                    style={{ float: 'right' }}
                    startIcon={<CircularProgress size={15}/>}
                >
                    Update
                </Button>
            );
        }
        return (
            <Button 
                variant="contained" 
                color='primary'
                style={{ float: 'right' }}
                onClick={this.handleAddOrUpdateBranch}
            >
                Update
             </Button>
        );
    }

    renderEditButton = () => {
        return (
            <Button 
                variant="contained" 
                color='primary'
                style={{ float: 'right' }}
                disableElevation
                onClick={() => this.handleEditBranch()}
            >
                Edit
             </Button>
        );
    }


    renderButtonBar = () => {
        const { mode } = this.state;
        if (mode === 'select'){
            return (
                <Grid item md={12} xs={12}>
                    {this.renderEditButton()}
                </Grid>
            );
        }
        if (mode === 'edit'){
            return (
                <Grid item md={12} xs={12}>
                    {this.renderDeleteButton()}
                    {this.renderSaveButton()}
                </Grid>
            );
        }
        if (mode === 'add'){
            return (
                <Grid item md={12} xs={12}>
                    {this.renderCancelButton()}
                    {this.renderSaveButton()}
                </Grid>
            );
        }
        return null
    }

    // ====================== Render Main ====================== //


render() {
    const { 
        branch,
        error,
        mode,
        logoDialogDelete,
        logoDialogAdd,
        branchDialogDelete
    } = this.state;
    const regError = error && error.registeredCompanyName;
    const compNumbError = error && error.companyNumber;
    const tradeNameError = error && error.tradingName;
    const websiteError = error && error.website;
    const emailError = error && error.contactEmail;
    const phoneError = error && error.contactPhone;
    const sumaryError = error && error.businessSummary;
    const locationError = error && error.serviceLocation;
    const lengthCompanyName = (150 - (branch && branch.registeredCompanyName && branch.registeredCompanyName.length)) || 0;
    const lengthCompanyNumber = (15 - (branch && branch.companyNumber && branch.companyNumber.length)) || 0;
    const lengthTradingName = (150 - (branch && branch.tradingName && branch.tradingName.length)) || 0;
    const lengthBusiness = (300 - (branch && branch.businessSummary && branch.businessSummary.length)) || 0;
    const disabled = mode === 'none' || mode === 'select';
    console.log(branch);
    return (
        <Grid 
            container 
            spacing={2}
            direction="row"
            justify="center"
            alignItems="stretch"
        >
            <Grid item md={6} xs={12}>
                <label>Logo</label>
                {this.renderLogo()}
            </Grid>
            <Grid item md={6} xs={12}>
                <label>Social NetWorks</label>
                {this.renderSocialsList()}
            </Grid>
            <Grid item md={6} xs={12} className="count-string">
                <TextField
                    variant="outlined"
                    margin='dense'
                    fullWidth
                    id="registeredCompanyName"
                    label="Registered Company Name*"
                    name="registeredCompanyName"
                    type="text"
                    autoFocus
                    disabled={disabled}
                    error={!!regError}
                    helperText={regError}
                    value={branch && branch.registeredCompanyName}
                    onChange={this.handleChange}
                    InputProps={{
                        endAdornment: 
                            <InputAdornment position="end">
                                {lengthCompanyName}
                            </InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item md={6} xs={12} className="count-string">
                <TextField
                    variant="outlined"
                    margin='dense'
                    fullWidth
                    id="companyNumber"
                    label="Company Number (NZBN/ABN)"
                    name="companyNumber"
                    type="text"
                    autoFocus
                    disabled={disabled}
                    error={!!compNumbError}
                    helperText={compNumbError}
                    value={branch && branch.companyNumber}
                    onChange={this.handleChange}
                    InputProps={{
                        endAdornment: 
                            <InputAdornment position="end">
                                {lengthCompanyNumber}
                            </InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item md={6} xs={12} className="count-string">
                <TextField
                    variant="outlined"
                    margin='dense'
                    fullWidth
                    id="tradingName"
                    label="Trading As*"
                    name="tradingName"
                    type="text"
                    autoFocus
                    disabled={disabled}
                    error={!!tradeNameError}
                    helperText={tradeNameError}
                    value={branch && branch.tradingName}
                    onChange={this.handleChange}
                    InputProps={{
                        endAdornment: 
                            <InputAdornment position="end">
                                {lengthTradingName}
                            </InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    variant="outlined"
                    margin='dense'
                    fullWidth
                    id="website"
                    label="Website"
                    name="website"
                    type="text"
                    autoFocus
                    disabled={disabled}
                    error={!!websiteError}
                    helperText={websiteError}
                    value={branch && branch.website}
                    onChange={this.handleChange}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    variant="outlined"
                    margin='dense'
                    fullWidth
                    id="contactEmail"
                    label="Contact Email*"
                    name="contactEmail"
                    type="text"
                    autoFocus
                    disabled={disabled}
                    error={!!emailError}
                    helperText={emailError}
                    value={branch && branch.contactEmail}
                    onChange={this.handleChange}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    variant="outlined"
                    margin='dense'
                    fullWidth
                    id="contactPhone"
                    label="Contact Phone*"
                    name="contactPhone"
                    type="text"
                    autoFocus
                    disabled={disabled}
                    error={!!phoneError}
                    helperText={phoneError}
                    value={branch && branch.contactPhone}
                    onChange={this.handleChange}
                />
            </Grid>
            <Grid item md={12} xs={12}>
                <LocationAutoComplete 
                    input={branch && branch.serviceLocation}
                    error={locationError}
                    label='Service Location*'
                    disabled={disabled}
                    handleChangeLocation={this.handleChangeLocation}
                />
            </Grid>
            <Grid item md={12} xs={12} className="count-string">
                <TextField
                    variant="outlined"
                    margin='dense'
                    fullWidth
                    id="businessSummary"
                    label="Business Summary"
                    name="businessSummary"
                    type="text"
                    autoFocus
                    disabled={disabled}
                    error={!!sumaryError}
                    helperText={sumaryError}
                    value={branch && branch.businessSummary}
                    onChange={this.handleChange}
                    multiline
                    rows={4}
                    InputProps={{
                        endAdornment: 
                            <InputAdornment position="end">
                                {lengthBusiness}
                            </InputAdornment>,
                    }}
                />
            </Grid>
            {this.renderButtonBar()}
            <BranchLogoDialogDelete 
                dialogOpen={logoDialogDelete}
                handleClose={this.closeLogoDelete}
                handleConfirm={this.confirmDelete}
                branch={branch}
            />
            <BranchLogoDialogAdd 
                dialogOpen={logoDialogAdd}
                handleClose={this.closeLogoAdd}
                handleConfirm={this.confirmAdd}
                branch={branch}
            />
            <BranchDialogDelete 
                dialogOpen={branchDialogDelete}
                handleClose={this.closeBranchDelete}
                handleConfirm={this.confirmBranchDelete}
                branch={branch}
            />
        </Grid>
    );
  }
}


const mapDispatchToProps = {
    firestoreAddBranch,
    firestoreUpdateBranch,
    firestoreGetBranchList,
    firestoreUploadBranchLogo
}

export default compose(connect(null,mapDispatchToProps))(BranchDetail)

