import { combineReducers } from "redux";
import firebaseWeb from "./firebase.reducer";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
// import firebase from './firebase.reducer';

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  firebaseWeb,
});

export default rootReducer;
