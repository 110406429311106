import React, { Component, forwardRef} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import moment from 'moment';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

class BranchTable extends Component {

// ====================== Inits ====================== //

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    // ====================== Functions ====================== //

    timeStampToFormat = ({stamp, format}) => {
        if (stamp) {
            return moment(stamp.toDate()).format(format);
        }
        return 'invalid'
    }

    // ====================== Firebase Functions ====================== //

    // ====================== Render Component ====================== //

    // ====================== Render Modal ====================== //

    // ====================== Render Main ====================== //

    render() {
        const { branchs } = this.props;
        let data = branchs || [];
        const option = {
            search: false,
            showTitle: false,
            paging: false,
            pageSize: 20,
            pageSizeOptions: [20, 50 ,100, 150, 200 ],
            align: 'center',
        };
        const columns = [
            { title: 'Trading Name', field: 'tradingName' },
            { title: 'Phone', field: 'contactPhone' },
            { title: 'Email', field: 'contactEmail' },
            { 
                title: 'Created on', 
                field: 'createAt',
                render: rowData => this.timeStampToFormat({
                    stamp: rowData.createAt,
                    format: 'D MMM YYYY',
                }),
            },
        ];
        return (
          <div className="tableSection userTable">
              <MaterialTable
                  options={option}
                  columns={columns}
                  data={data}
                  icons={tableIcons}
                  style={{ boxShadow: "none", borderBottom: 0 }}
                  onRowClick={(event, rowData) => this.props.onRowClick(rowData)}
              />
          </div>
        ); 
      }
}

const mapStateToProps = (state) => {
  return {
    branchs: state.firebaseWeb.branchs,
  }
}

const mapDispatchToProps = {
    
}

export default compose(connect(mapStateToProps,mapDispatchToProps))(BranchTable)

