import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { 
  CssBaseline,
  Container, 
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { 
  PersonAdd,
  CloudDownloadOutlined,
  CloudUploadOutlined,
} from '@material-ui/icons';
import Navbar from '../00_Nav/nav_bar';
import RightNav from '../00_Nav/right_nav';
import { 
  firestoreGetUserGroup,
  firestoreGetBranchList,
  firestoreGetUserList
} from  '../../store/actions/firebase.action';
import UserDetail from './user_detail';
import UserGroupList from './user_group_list';
import UserTableList from './user_table_list';
import { exportUsersToCSV } from './functions/export_user_csv';
import CsvUserDialogUpload from './dialog/csv_user_dialog_upload';

class UserManagement extends Component {
  // ====================== Inits ====================== //

  constructor(props) {
    super(props);
    this.state = {
      error: '',
      loading: false,
      selectedUser: null,
      group : 'all',
      branch: 'all',
      csvUserUpload: false,
    };
  }

  componentDidMount = () => {
    this.props.firestoreGetUserGroup();// get Group
    this.props.firestoreGetBranchList();// get Branch
    this.props.firestoreGetUserList();// get users
  }

  // ====================== Functions ====================== //

  newUserData = () => {
      this.setState({
        selectedUser: { id: null }
      });
  }

  onRowClick = (data) => {
    let selectedUser = {...data};
    delete selectedUser.tableData;
    delete selectedUser.fullName;
    delete selectedUser.status;
    delete selectedUser.groupName;
      this.setState({
        selectedUser: data
      });
  }

  handleSelect = (e, id) => {
    this.setState({
      [id]: e.target.value
    });
  }

  openCsvUserUpload = () => {
    this.setState({
      csvUserUpload: true,
    })
  }

  closeCsvUserUpload = () => {
    this.setState({
      csvUserUpload: false,
    });
  }

  confirmCsvUserUpload = (files) => {
    this.setState({
      csvUserUpload: false,
    }, () => this.props.firestoreGetUserList());
  }

  // ====================== Firebase Functions ====================== //

  // ====================== Các hàm render ====================== //

  renderSectionUserTop = () => {
    const { selectedUser } = this.state;
    return (
      <Grid 
        container 
        spacing={2}
        direction="row"
        justify="center"
        alignItems="stretch"
        style={{ minHeight: 375 }}
      >
        <Grid item md={8} xs={12} className='padding-grid right'>
          <h3 className='regular-title'>User Details</h3>
          <UserDetail 
            user={selectedUser}
            onCancel={this.newUserData}
          />
        </Grid>
        <Grid item md={4} xs={12} className='grid-user-group padding-grid left'>
          <h3 className='regular-title'>Groups</h3>
          <UserGroupList />
        </Grid>
      </Grid>
    );
  }

  renderBranchDropDown = () => {
    const { branchs } = this.props;
    const { branch } = this.state;
    const menuItem = branchs && branchs.map((branch, index) => {
        return (
            <MenuItem key={index} value={branch.id}>{branch.tradingName}</MenuItem>
        );
    });
    return (
        <FormControl 
            variant="outlined"
            margin='dense'
            fullWidth
            className='table-select'
        >
          <InputLabel id="branchLabel">Branch</InputLabel>
          <Select
            labelId="branchLabel"
            id="branch"
            value={branch}
            onChange={(event) => this.handleSelect(event, 'branch')}
            label="Branch"
          >
              <MenuItem value="all">
                All Branches
              </MenuItem>
              { menuItem }
          </Select>
        </FormControl>
    );
  }

  renderGroupDropDown = () => {
    const { userGroups } = this.props;
    const { group } = this.state;
    const menuItem = userGroups && userGroups.map((group, index) => {
        return (
            <MenuItem key={index} value={group.id}>{group.name}</MenuItem>
        );
    });
    return (
        <FormControl 
            variant="outlined"
            margin='dense'
            fullWidth
            className='table-select'
        >
            <InputLabel id="groupLabel">Group</InputLabel>
            <Select
                labelId="groupLabel"
                id="group"
                value={group}
                onChange={(event) => this.handleSelect(event, 'group')}
                label="Group"
            >
                <MenuItem value="all">
                  All Groups
                </MenuItem>
                { menuItem }
            </Select>
        </FormControl>
    );
  }

  renderSectionUserBottom = () => {
    const { users, userGroups } = this.props;
    const { branch, group } = this.state;
    let data = users || [];
    if (branch !== 'all'){
      data = data.filter(d => d.branch === branch);
    }
    if (group !== 'all'){
      data = data.filter(d => d.group === group);
    }
    data = data.map(d => {
      const groupData = userGroups && userGroups.find(ug => ug.id === d.group);
      const groupName = (groupData && groupData.name) || ''
      return {
        ...d,
        fullName: `${d.firstName} ${d.lastName}`,
        status: d.active ? 'Active' : 'Deactivated',
        groupName,
      }
    })
    return (
      <Grid container spacing={2} className='section-user-bottom-container'>
        <Grid item md={8} xs={12} className='table-title-bar'>
          <Grid container spacing={1}>
            <Grid item>
              <h3 className="regular-title">User Management</h3>
            </Grid>
            <Grid item md={3} xs={12}>
              { this.renderBranchDropDown() }
            </Grid>
            <Grid item md={3} xs={12}>
              { this.renderGroupDropDown() }
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} xs={12} >
          <Grid container spacing={1} justify="flex-end" alignItems="flex-end">
            { this.renderExportImportUser() }
            <Grid item>
              <div className='total-user'>Total User: {data.length || 0}</div>
            </Grid>
            <Grid item>
              <Button
                className='add-btn'
                variant='outlined'
                color="primary"
                startIcon={<PersonAdd />}
                onClick={() => this.newUserData()}
              >Add User</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <UserTableList
            onRowClick={(row) => this.onRowClick(row)}
            data={data}
          />
        </Grid>
      </Grid>
    );
  }

  renderExportImportUser = () =>{
    const { users, userGroups, branchs } = this.props;
    const disabled = !users || (users && users.length <= 0);
    return (
      <Grid item>
        <Tooltip title="Export all users to csv">
          <span>
            <IconButton 
              aria-label="download"
              onClick={() => exportUsersToCSV({ users, groups: userGroups, branchs })}
              disabled={disabled}
            >
              <CloudDownloadOutlined style={{ color: disabled ? '#c8c8c8' : '#333' }}/>
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Import  all users by csv">
          <span>
            <IconButton 
              aria-label="upload"
              onClick={() => this.openCsvUserUpload()}
            >
                <CloudUploadOutlined style={{ color: '#333' }}/>
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    );
  }

  // ====================== Main ====================== //
  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to={'/signin'}/>
    const { csvUserUpload } = this.state;
    return (
      <div className='root-container'>
        <CssBaseline />
        <Navbar title="User Management" right={<RightNav />}/>
        <Container maxWidth='xl'>
          {this.renderSectionUserTop()}
          {this.renderSectionUserBottom()}
        </Container>
        <CsvUserDialogUpload 
          dialogOpen={csvUserUpload}
          handleClose={this.closeCsvUserUpload}
          handleConfirm={this.confirmCsvUserUpload}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    users: state.firebaseWeb.users,
    userGroups: state.firebaseWeb.userGroups,
    branchs: state.firebaseWeb.branchs,
  }
}

const mapDispatchToProps = {
  firestoreGetUserGroup,
  firestoreGetBranchList,
  firestoreGetUserList
}

export default compose(connect(mapStateToProps,mapDispatchToProps))(UserManagement)
