import FileSaver from 'file-saver';
import moment from 'moment';
import { BRAND_NAME } from '../../../config/config';
import { jsonToCSV } from 'react-papaparse';

const columns = [
    'branch',
    'group',
    'username',
    'pincode',
    'firstName',
    'lastName',
    'phone',
    'email',
    'break',
    'active',
];

export const exportUsersToCSV = ({ users, groups, branchs }) => {
    // =========================  Provider =========================
    // // =========================  Row Data =========================
    const rowTableHeader = 'Branch,Group,Username,Pincode,FirstName,LastName,Phone,Email,Break,Active\n';
    const bodyData = users.map((item) => {
        const branch = branchs.find(b => b.id === item.branch);
        const branchName = branch && branch.tradingName;
        const group = groups.find(b => b.id === item.group);
        const groupName = group && group.name;
        return {
            ...item,
            branch: branchName,
            group: groupName,
        }
    });
    const rowTableBody = jsonToCSV(bodyData, { columns: columns, header: false, quotes: true });
    // // =========================  CSV =========================
    const csvBuffer = `${rowTableHeader}${rowTableBody}`;
    const currentTime = moment().format('DD_MM_YYYY-HH_mm_ss');
    const fileName = `[${BRAND_NAME}] All User - ${currentTime}`;
    const fileType = 'text/csv;charset=utf-8;';
    const fileExtension = '.csv';
    const data = new Blob([csvBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}