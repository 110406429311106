const initState = {
  loginError: "",
  loading: false,
  users: null,
  userGroups: null,
  branchs: null,
  timeTracking: null,
  products: null,
  contacts: null,
  templates: null,
  contactTemplates: null,
};
const firebaseReducer = (state = initState, action) => {
  switch (action.type) {
    case "firebase_loading":
      return {
        ...state,
        loading: true,
      };
    case "firebase_login_success":
      return {
        ...state,
        loginError: "",
        loading: false,
      };
    case "firebase_login_fail":
      return {
        ...state,
        loginError: action.payload,
        loading: false,
      };
    case "get_user_group_success":
      return {
        ...state,
        userGroups: action.payload,
      };
    case "get_user_list_success":
      return {
        ...state,
        users: action.payload,
      };
    case "get_branch_list_success":
      return {
        ...state,
        branchs: action.payload,
      };
    case "get_time_tracking_list_success":
      return {
        ...state,
        timeTracking: action.payload,
      };
    case "get_product_list_success":
      return {
        ...state,
        products: action.payload,
      };
    case "get_contact_list_success":
      return {
        ...state,
        contacts: action.payload,
      };
    case "get_template_list_success":
      return {
        ...state,
        templates: action.payload,
      };
    case "get_contact_template_list_success":
      return {
        ...state,
        contactTemplates: action.payload,
      };
    default:
      return state;
  }
};

export default firebaseReducer;
