import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { firebaseLogin } from '../../store/actions/firebase.action';
import { BRAND_NAME } from '../../config/config';

class SignIn extends Component {

    // -------------------------- STATE --------------------------
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
        }
    }
    // -------------------------- FUNCTIONS --------------------------
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        this.props.firebaseLogin({ email, password });
    }     
    // -------------------------- RENDER --------------------------

    renderCopyright() {
        return (
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="#">
              {BRAND_NAME}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
      }

    renderLoginButton() {
        const { loading } = this.props;
        if (loading) {
            return (
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="submit"
                    disabled
                >
                    Signing In <CircularProgress className="spinner" size={15}/>
                </Button>
            );
        }
        return (
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="submit"
            >
                Sign In
            </Button>
        );
    }
    
    // -------------------------- MAIN --------------------------
    render() {
        const { loginError, auth } = this.props;
        if (auth.uid) return <Redirect to={'/'}/>
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className='paper'>
                    <form className="form" noValidate onSubmit={this.handleSubmit} style={{ marginTop: '80px' }}>
                        <Typography component="h1" variant="h5" align='center'>
                            Sign in
                        </Typography>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={this.handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={this.handleChange}
                        />
                        <FormControlLabel
                            control={<Checkbox checked value="remember" color="primary" />}
                            label="Remember me"
                        />
                        {this.renderLoginButton()}
                    </form>
                    <div className="red-text center">
                        { loginError ? <p>{loginError}</p> : null }
                    </div>
                </div>
                <Box mt={8}>
                    {this.renderCopyright()}
                </Box>
            </Container>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.firebase.auth,
    loading: state.firebaseWeb.loading,
    loginError: state.firebaseWeb.loginError,
});

const mapDispatchToProps = {
    firebaseLogin
}
  

export default connect(mapStateToProps,mapDispatchToProps)(SignIn);
