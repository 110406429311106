import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TableSortLabel,
  FormControl,
  IconButton,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import noAvatar from "../../asset/img/no-avatar.png";
import { MAX_INPUT } from "../../config/config";

class TimeTrackingTable extends Component {
  // ====================== Inits ====================== //

  constructor(props) {
    super(props);
    this.state = {
      nullData: Array(MAX_INPUT + 8).fill(null),
      edited: [],
      editError: [],
      matrix: null,
    };
  }

  // ====================== Functions ====================== //

  isSelected = (data) => {
    const { selected } = this.props;
    const exist =
      selected &&
      selected.filter((c) => c.username === data.username && c.stringDate === data.stringDate)
        .length > 0;
    if (exist) {
      return true;
    }
    return false;
  };

  isCheckedAll = () => {
    const { selected, reportData } = this.props;
    const body = reportData && reportData.body && reportData.body;
    const row = body && body.filter((b) => b.type === "row");
    if (selected && row && selected.length > 0 && row.length === selected.length) {
      return true;
    }
    return false;
  };

  isEdited = ({ rowIndex, cellIndex }) => {
    const { edited } = this.props;
    const exist =
      edited && edited.find((e) => e.rowIndex === rowIndex && e.cellIndex === cellIndex);
    return exist;
  };

  isEditError = ({ rowIndex, cellIndex }) => {
    const { editError } = this.props;
    const exist =
      editError && editError.find((e) => e.rowIndex === rowIndex && e.cellIndex === cellIndex);
    return exist;
  };

  // ====================== Data Functions ====================== //

  getHeaderByRange = (range) => {
    let items = [];
    if (range) {
      for (let i = 0; i < range; i++) {
        const number = Math.round(i / 2) + (i % 2 === 0 ? 1 : 0);
        const id = i % 2 === 0 ? `in${number}` : `out${number}`;
        const label = i % 2 === 0 ? `In ${number}` : `Out ${number}`;
        const data = { id, label, align: "center", sortable: false };
        items = [...items, data];
      }
    }
    const preHeader = [
      { id: "username", label: "Username", align: "left", sortable: false },
      { id: "fullname", label: "Full Name", align: "left", sortable: true },
      { id: "group", label: "Group", align: "left", sortable: true },
      { id: "date", label: "Date", align: "center", sortable: false },
      { id: "day", label: "Day", align: "center", sortable: false },
    ];
    const endHeader = [
      { id: "workingtime", label: "Working Time", align: "center", sortable: false },
      { id: "breakingtime", label: "Breaking Time", align: "center", sortable: false },
    ];
    return [...preHeader, ...items, ...endHeader];
  };

  refineHeaderLength = () => {
    const { reportData, isEdit } = this.props;
    if (isEdit) {
      return this.getHeaderByRange(MAX_INPUT);
    }
    if (reportData && reportData.header) {
      return reportData.header;
    }
    return this.getHeaderByRange(0);
  };

  handleTimeChange = ({ row, rowIndex, cell, cellIndex, value }) => {
    const { edited } = this.props;
    const stringDate = row && row.stringDate;
    let newValue = null;
    if (value) {
      const stringTime = value && moment(value).format("hh:mm A");
      newValue = moment(`${stringDate} ${stringTime}`, "DD/MM/YYYY hh:mm A");
    }
    const data = { row, rowIndex, cell, cellIndex, value: newValue };
    const isUpdate =
      edited && edited.find((e) => e.rowIndex === rowIndex && e.cellIndex === cellIndex);
    const newEdited = isUpdate
      ? edited.map((e) => {
          return e.rowIndex === rowIndex && e.cellIndex === cellIndex ? data : e;
        })
      : [...edited, data];
    // tạo ma trận dữ liệu
    const matrix = this.generateMatrixDataFromReportAndEdited({ edited: newEdited });
    // validate nó
    this.validateEdited({ edited: newEdited, matrix });
  };

  generateMatrixDataFromReportAndEdited = ({ edited }) => {
    const { reportData } = this.props;
    const body = reportData && reportData.body;
    // Tạo ma trận dữ liệu mix reportData và edited data
    const matrix =
      body &&
      body.map((row, rowIndex) => {
        const fullData = row.fullData;
        return fullData
          ? fullData.map((cell, cellIndex) => {
              const exist =
                edited && edited.find((e) => e.rowIndex === rowIndex && e.cellIndex === cellIndex);
              if (exist) {
                const date = exist.value ? moment(exist.value)._d : null;
                const cellData = {
                  date,
                  recordId: null,
                };
                return cellData;
              }
              return {
                date: cell && cell.date,
                recordId: cell && cell.recordId,
              };
            })
          : null;
      });
    return matrix;
  };

  validateEdited = ({ edited, matrix }) => {
    // Kiểm tra lỗi trong dữ liệu edit
    let editError = [];
    edited &&
      edited.forEach((e) => {
        const rowIndex = e.rowIndex;
        const cellIndex = e.cellIndex;
        let isError = false;
        const row = matrix && matrix[rowIndex];
        const cell = row && row[cellIndex]; // dữ liệu của cell
        if (!cell.date) {
          // là xóa
          const after = row && row[cellIndex + 1];
          const afterDate = after && after.date;
          if (afterDate) {
            // tồn tại dữ liệu sau
            isError = true;
          }
        } else {
          // update hoặc add
          if (cellIndex > 0) {
            // nếu có dữ liệu trước và lớn hơn dữ liệu hiện tại
            const pre = row && row[cellIndex - 1];
            const preDate = pre && pre.date;
            const cellDate = cell.date;
            if (!preDate || (preDate && preDate >= cellDate)) {
              isError = true;
            }
          }
        }
        if (isError) {
          editError = [...editError, { rowIndex, cellIndex }];
        }
      });
    this.setState(
      {
        matrix,
      },
      () => {
        this.props.isEditValid({ edited, editError });
      }
    );
  };

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  getInOutHeaderByRange = () => {
    const { reportData } = this.props;
    let items = [];
    if (reportData && reportData.maxRange) {
      for (let i = 0; i < reportData.maxRange; i++) {
        const number = Math.round(i / 2) + (i % 2 === 0 ? 1 : 0);
        const id = i % 2 === 0 ? `in${number}` : `out${number}`;
        const label = i % 2 === 0 ? `In ${number}` : `Out ${number}`;
        const data = { id, label, align: "center", sortable: false };
        items = [...items, data];
      }
    }
    return items;
  };

  renderTableHead = () => {
    const { reportData, orderBy, order, isEdit } = this.props;
    const checkAll = this.isCheckedAll();
    const disabled = !(reportData && reportData.body && reportData.body.length > 0) || isEdit;
    const header = this.refineHeaderLength();
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={checkAll}
              disabled={disabled}
              onChange={() => this.props.handleCheckAll()}
              inputProps={{ "aria-label": "select all desserts" }}
              color="primary"
            />
          </TableCell>
          {header &&
            header.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                padding="default"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {this.renderTableSortLabel(headCell, disabled)}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableSortLabel = (headCell, disabled) => {
    const { orderBy, order } = this.props;
    if (headCell.sortable) {
      return (
        <TableSortLabel
          disabled={disabled}
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
          onClick={() => this.props.handleSort(headCell.id)}
        >
          {headCell.label}
        </TableSortLabel>
      );
    }
    return headCell.label;
  };

  renderTableBody = () => {
    const { isEdit, reportData } = this.props;
    const body = reportData && reportData.body;
    console.log({ body });
    if (isEdit) {
      return (
        <TableBody>
          {body && body.map((row, index) => this.renderTableRowEdit(row, index))}
        </TableBody>
      );
    }
    return (
      <TableBody>{body && body.map((row, index) => this.renderTableRow(row, index))}</TableBody>
    );
  };

  // ====================== Render Table Default ====================== //

  renderTableRow = (row, index) => {
    if (row.type === "row") {
      const isItemSelected = this.isSelected(row);
      const labelId = `table-checkbox-${index}`;
      const data = row.data;
      return (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={index}
          selected={isItemSelected}
        >
          <TableCell align="left" padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              color="primary"
              inputProps={{ "aria-labelledby": labelId }}
              onChange={() => this.props.handleCheck(row)}
            />
          </TableCell>
          <TableCell align="left">{row.username}</TableCell>
          <TableCell align="left">{row?.user?.fullname}</TableCell>
          <TableCell align="left">{row.groupName}</TableCell>
          <TableCell align="center">{row.stringDate}</TableCell>
          <TableCell align="center">{row.stringDay}</TableCell>
          {data && data.map((cell, cellIndex) => this.renderTableCell({ cell, cellIndex }))}
          <TableCell align="center">{row.working}</TableCell>
          <TableCell align="center">{row.breaking}</TableCell>
        </TableRow>
      );
    } else if (row.type === "total") {
      const rowData = row.data;
      return (
        <TableRow className="total-row" key={index}>
          {rowData &&
            rowData.map((item, rowIndex) => {
              return (
                <TableCell key={rowIndex} align="center">
                  {item}
                </TableCell>
              );
            })}
        </TableRow>
      );
    }
    return null;
  };

  renderTableCell = ({ cell, cellIndex }) => {
    if (cell) {
      return (
        <TableCell align="center" key={cellIndex} className="report-data-cell">
          <p style={{ color: cellIndex % 2 === 0 ? "green" : "red" }}>{cell.stringTime}</p>
          {this.renderPhoto(cell)}
          {this.renderLocation(cell)}
        </TableCell>
      );
    }
    return (
      <TableCell align="center" key={cellIndex}>
        {""}
      </TableCell>
    );
  };

  renderPhoto = (cell) => {
    const { showPhoto } = this.props;
    if (showPhoto) {
      if (cell && cell.screenshot) {
        return (
          <img
            className="table-photo"
            src={`data:image/jpeg;base64,${cell.screenshot}`}
            alt={cell.id}
          />
        );
      } else {
        return <img className="table-photo" src={noAvatar} alt={cell.id} />;
      }
    }
    return null;
  };

  renderLocation = (cell) => {
    const { showLocation } = this.props;
    if (showLocation) {
      if (cell && cell.subUrl) {
        return <p className="table-location">{cell.subUrl}</p>;
      } else {
        return <p>no location data</p>;
      }
    }
    return null;
  };

  // ====================== Render Table Edit ====================== //

  renderTableRowEdit = (row, index) => {
    if (row.type === "row") {
      const isItemSelected = this.isSelected(row);
      const labelId = `table-checkbox-${index}`;
      const data = row.fullData;
      return (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={index}
          selected={isItemSelected}
        >
          <TableCell align="left" padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              color="primary"
              inputProps={{ "aria-labelledby": labelId }}
              disabled
            />
          </TableCell>
          <TableCell align="left">{row.username}</TableCell>
          <TableCell align="left">{row.fullName}</TableCell>
          <TableCell align="left">{row.groupName}</TableCell>
          <TableCell align="center">{row.stringDate}</TableCell>
          <TableCell align="center">{row.stringDay}</TableCell>
          {data &&
            data.map((cell, cellIndex) =>
              this.renderTableCellEdit({ cell, cellIndex, row, rowIndex: index })
            )}
          <TableCell align="center">{row.working}</TableCell>
          <TableCell align="center">{row.breaking}</TableCell>
        </TableRow>
      );
    } else if (row.type === "total") {
      const { nullData } = this.state;
      return (
        <TableRow className="total-row" key={index}>
          {nullData &&
            nullData.map((item, rowIndex) => {
              return (
                <TableCell key={rowIndex} align="center">
                  {item}
                </TableCell>
              );
            })}
        </TableRow>
      );
    }
    return null;
  };

  renderTableCellEdit = ({ cell, cellIndex, row, rowIndex }) => {
    const { edited } = this.props;
    const { loading, matrix } = this.state;
    const defaultOpenValue = moment().hours(0).minute(0).second(0);
    const matrixData = matrix && matrix[rowIndex][cellIndex] && matrix[rowIndex][cellIndex];
    const matrixDate = (matrixData && matrixData.date && moment(matrixData.date)) || null;
    const cellDate = (cell && cell.date && moment(cell.date)) || null;
    // có edit mới có ma trận mới nếu không là dữ liệu hiển thị là từ dữ liệu
    const time = edited.length > 0 ? matrixDate : cellDate;
    const isEdited = this.isEdited({ rowIndex, cellIndex });
    const isEditError = this.isEditError({ rowIndex, cellIndex });
    const disabled = loading;
    return (
      <TableCell align="center" key={cellIndex} className="report-data-cell edit">
        <div style={{ display: "flex" }}>
          <FormControl fullWidth>
            <TimePicker
              picker="time"
              showSecond={false}
              value={time}
              className={`time-picker-scroll edit-table-cell ${isEdited ? "edited" : ""} ${
                isEditError ? "error" : ""
              }`}
              format="hh:mm A"
              defaultOpenValue={defaultOpenValue}
              onChange={(value) => this.handleTimeChange({ row, rowIndex, cell, cellIndex, value })}
              use12Hours
              disabled={disabled}
              inputReadOnly
              clearIcon={null}
              minuteStep={5}
            />
          </FormControl>
          {this.renderDeleteButton({ row, rowIndex, cell, cellIndex, time })}
        </div>
      </TableCell>
    );
  };

  renderDeleteButton = ({ row, rowIndex, cell, cellIndex, time }) => {
    if (time) {
      return (
        <IconButton
          aria-label="delete"
          size="small"
          className="edit-delete"
          onClick={() => this.handleTimeChange({ row, rowIndex, cell, cellIndex, value: null })}
        >
          <Cancel fontSize="small" style={{ color: "red" }} />
        </IconButton>
      );
    }
    return null;
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    return (
      <Paper style={{ width: "100%", marginTop: 10, marginBottom: 30 }}>
        <TableContainer className="report-table">
          <Table stickyHeader aria-label="sticky table">
            {this.renderTableHead()}
            {this.renderTableBody()}
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}

export default compose(connect(null, null))(TimeTrackingTable);
