import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    CircularProgress
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase';
import moment from 'moment';

class TimeTrackingDialogDelete extends Component {

  // ====================== Inits ====================== //
    
    constructor(props) {
        super(props);
        // state
        this.state = {
            error: '',
            loading: false,
        };
    }

    // ====================== Functions ====================== //

    // ====================== Firebase Functions ====================== //

    handleDeleteTimeTracking = () => {
        const { timeTracking } = this.props;
        this.setState({
            loading: true,
        }, () => {
          const timeTrackingRef= firebase.firestore().collection('checkInOut');
          timeTrackingRef.doc(timeTracking.id).delete().then(() => {
            this.setState({
                loading: false,
            }, () => {
                this.props.handleConfirm();
            })
          });
        })
    }

    // ====================== Render Component ====================== //


    renderDeleteButton = () =>{
        const { loading } = this.state;
        if (loading) {
          return (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className='add-btn'
              disabled
              startIcon={<CircularProgress size={15}/>}
            >
              Delete
            </Button>
          );
        }
        return (
          <Button 
            onClick={this.handleDeleteTimeTracking} 
            type="submit"
            variant="contained"
            className='add-btn'
            style={{ backgroundColor: 'red', color: '#fff' }}
          >
            Delete
          </Button>
        );
    }

    
    // ====================== Render Main ====================== //

    render() {
        const { error, loading } = this.state;
        const { dialogOpen, handleClose, timeTracking, selectedUser } = this.props;
        const checkedAt = timeTracking && timeTracking.checkedAt;
        const stringDate = checkedAt && moment(checkedAt.toDate()).format('ddd DD/MM/YYYY hh:mm A');
        const firstName = selectedUser && selectedUser.firstName;
        const lastName = selectedUser && selectedUser.lastName;
        const fullName = firstName && lastName && `${firstName} ${lastName}`;
        return (
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='sm'
                fullWidth
            >
            <DialogTitle id="scroll-dialog-title">Remove record "{stringDate}" - by {fullName}</DialogTitle>
            <DialogContent dividers >
              <p>Are you sure to delete record "{stringDate}". This action can't be undone.</p>
              <div className="red-text center">
                  { error ? <p>{error}</p> : null }
              </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={loading}>
                    Cancel
                </Button>
                {this.renderDeleteButton()}
            </DialogActions>
        </Dialog>
    );
  }
}

const mapDispatchToProps = {
    
}
  
export default compose(connect(null,mapDispatchToProps))(TimeTrackingDialogDelete)