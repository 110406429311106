import React, { Component } from 'react';
import { IconButton, Badge } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';

export default class RightNav extends Component {
    render() {
        return (
            <IconButton aria-label="cart">
                <Badge badgeContent={4} color="secondary">
                    <Notifications style={{ color: '#fff' }}/>
                </Badge>
            </IconButton>
        );
    }
}
