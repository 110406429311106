import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { MAP_API_KEY } from '../config/config';

function loadScript(src, position, id) {
    if (!position) {
        return;
    }
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export default function GoogleMaps(props) {
    
    const classes = useStyles();
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);
    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }
        loaded.current = true;
    }

    const fetch = React.useMemo(() => throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
    }, 200),[],);
    
    React.useEffect(() => {
        let active = true;
    
        if (!autocompleteService.current && window.google) {
          autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
          return undefined;
        }
    
        if (props.input === '') {
            setOptions(props.input ? [props.input] : []);
            return undefined;
        }
        
        fetch({ 
            input: props.input,
            componentRestrictions: { 
                country: ['au','nz'] 
            }  
        }, (results) => {
          if (active) {
            let newOptions = [];
            if (results) {
                newOptions = [...newOptions, ...results];
            }
            setOptions(newOptions);
          }
        });
    
        return () => {
          active = false;
        };
}, [props.input, fetch]);
    return (
        <Autocomplete
            getOptionLabel={(option) => {return (typeof option === 'string' ? option : option.description)}}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={props.input}
            disabled={props.disabled}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
            }}
            onInputChange={(event, newInputValue) => {
                props.handleChangeLocation(newInputValue);
            }}
            renderInput={(params) => (
                <TextField 
                    {...params} 
                    margin='dense' 
                    label={props.label}
                    variant="outlined"
                    fullWidth 
                    value={props.input}
                    error={!!props.error}
                    helperText={props.error}
                />
            )}
            renderOption={(option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches && matches.map((match) => [match.offset, match.offset + match.length]),
                );
                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <LocationOnIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs>
                            {parts && parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))}
                            <Typography variant="body2" color="textSecondary">
                                {option.structured_formatting.secondary_text}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}
