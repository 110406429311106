import React, { Component} from 'react';
import { 
    Dialog, 
    DialogTitle,
    List,
    ListItemText,
    ListItem,
    ListItemAvatar,
    Avatar
} from '@material-ui/core';
import { BackupOutlined, CameraAltOutlined } from '@material-ui/icons';
import { compose } from 'redux';
import { connect } from 'react-redux';

class UserAvatarDialogSelect extends Component {

  // ====================== Inits ====================== //
    
    constructor(props) {
        super(props);
        // state
        this.state = {
            error: '',
            loading: false,
        };
    }

    // ====================== Functions ====================== //

    // ====================== Firebase Functions ====================== //

    // ====================== Render Component ====================== //
    
    // ====================== Render Main ====================== //

    render() {
        const { dialogOpen, handleClose, handleSelect } = this.props;
        return (
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                scroll='paper'
            >
            <DialogTitle id="scroll-dialog-title">Select Option</DialogTitle>
            <List>
                <ListItem button onClick={() => handleSelect('upload')} >
                    <ListItemAvatar>
                    <Avatar>
                        <BackupOutlined />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary='Upload from local device' />
                </ListItem>
                <ListItem button onClick={() => handleSelect('camera')} >
                    <ListItemAvatar>
                    <Avatar>
                        <CameraAltOutlined />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary='Take picture from camera' />
                </ListItem>
            </List>
        </Dialog>
    );
  }
}

const mapDispatchToProps = {
}
  
export default compose(connect(null,mapDispatchToProps))(UserAvatarDialogSelect)