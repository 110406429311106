import React, { Component} from 'react';
import {
    Dialog,
    DialogTitle,
    IconButton
} from '@material-ui/core';
import { FlipCameraIosOutlined } from '@material-ui/icons';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

// Thiết lập dành cho việc không auth lại

class UserAvatarDialogCamera extends Component {

    // ====================== Inits ====================== //

    constructor(props) {
        super(props);
        this.state = {
            front: true,
            cameraOn: true,
        };
    }

    // ====================== Functions ====================== //

    handleSwichCamera = () => {
        this.setState({
            front: !this.state.front
        });
    }

    // ====================== Firebase Functions ====================== //
    
    // ====================== Render Component ====================== //

    renderCamera = () => {
        const { handleTakePhoto } = this.props;
        const { front } = this.state;
        const { cameraOn } = this.state;
        if (cameraOn) {
            return (
                <Camera
                    onTakePhotoAnimationDone={handleTakePhoto}
                    idealFacingMode = {front ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT}
                    imageType = {IMAGE_TYPES.JPG}
                    imageCompression = {0.96}
                    isImageMirror={front}
                    idealResolution = {{width: 512, height: 512}}
                    onCameraStart = { (stream) => { this.stream = stream } }
                />
            );
        }
        return null;
    }
    
    // ====================== Render Main ====================== //

    render() {
        const { dialogOpen, handleClose } = this.props;
        return (
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                fullWidth
                maxWidth='md'
                id='dialog-take-picture'
            >
            <div className='inline-section'>
                <DialogTitle id="scroll-dialog-title">Take Picture</DialogTitle>
                <IconButton 
                    color="primary" 
                    aria-label="rotate camera" 
                    component="span"
                    onClick={this.handleSwichCamera}
                >
                    <FlipCameraIosOutlined />
                </IconButton>
            </div>
            {this.renderCamera()}
            </Dialog>
        );
    }
}

export default UserAvatarDialogCamera;