import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import collection from "lodash/collection";
import { MAX_INPUT } from "../../../config/config";

momentDurationFormatSetup(moment); // setup cho moment duration format
// cải thiện dữ liệu query được biến thành dạng dữ liệu xử lý được
export const refineData = ({
  users,
  userGroups,
  timeTracking,
  selectedUser,
  groupId,
  order,
  orderBy,
}) => {
  console.log({ users });
  let result = [];
  let maxRange = 0;
  let filterUsers =
    users &&
    users.map((user) => {
      const fullname = `${user.firstName} ${user.lastName}`;
      return {
        ...user,
        fullname,
      };
    });
  if (selectedUser) {
    // Lọc selected user
    filterUsers = filterUsers.filter((f) => f.id === selectedUser.id);
  }
  if (groupId !== "all") {
    // Lọc group
    filterUsers = filterUsers.filter((f) => f.group === groupId);
  }
  if (order && orderBy) {
    filterUsers = collection.orderBy(filterUsers, [orderBy], [order]);
  }

  if (filterUsers && userGroups && timeTracking) {
    filterUsers.forEach((user) => {
      const group = userGroups.find((group) => group.id === user.group);
      const groupName = group && group.name;
      const time = timeTracking.filter((check) => check.user === user.id);
      const username = user && user?.username;
      const fullName = user && user?.fullname;
      //  ======== Kiểu dữ liệu ======== //
      //  ===[user01, user02, user03]=== //
      //  ============================== //
      if (time.length > 0) {
        const checking = groupByDay({
          timeArr: time,
          key: "stringDate",
          count: "time",
          user,
          groupName,
        });
        const checkingRange = checking && checking.maxRange;
        if (checkingRange >= maxRange) {
          maxRange = checkingRange;
        }
        // Lấy data cần thiết
        const weekData = checking && checking.weekData;
        const workingTotal = checking && checking.workingTotal;
        const breakingTotal = checking && checking.breakingTotal;
        const data = {
          username,
          fullName,
          groupName,
          weekData,
          workingTotal,
          breakingTotal,
        };
        //  ======== Kiểu dữ liệu ======== //
        //  =[user01[day1,day2,day3]]....= //
        //  ============================== //
        result = [...result, data];
      }
    });
  }
  const reportData = convertTableData(result, maxRange);
  //  ======== Kiểu dữ liệu ======== //
  //  =header[Array]footer[Array]Total....= //
  //  ============================== //
  return reportData;
};

// Nhón dữ liệu theo ngày
const groupByDay = ({ timeArr, key, count, user, groupName }) => {
  const array =
    timeArr &&
    timeArr.map((item) => {
      const date = item.checkedAt && item.checkedAt.toDate();
      const startDate = date && moment(date).startOf("day");
      const stringDate = date && moment(date).format("DD/MM/YYYY");
      const stringTime = date && moment(date).format("hh:mm A");
      const stringDay = date && moment(date).format("ddd");
      const time = moment(date).diff(startDate, "seconds");
      return {
        date,
        time,
        recordId: item.id,
        stringDate,
        stringDay,
        stringTime,
        screenshot: item.screenshot,
        subUrl: item.subUrl,
      };
    });
  if (array) {
    let weekData = [];
    let maxRange = 0;
    const weekDayUnique = array.reduce(
      (unique, item) => (unique.includes(item[key]) ? unique : [...unique, item[key]]),
      []
    );
    weekDayUnique.forEach((wd) => {
      const data = array.filter((a) => a[key] === wd);
      // ======= Tính tổng breaking, working time ======= //
      const calculating = data.length % 2 === 0 ? data : data.slice(0, data.length - 1); // chia cặp data để tính tổng
      const calcuLength = (calculating && calculating.length) || 0;
      // Dữ liệu lý thuyết
      let total = 0;
      let working = 0;
      let breaking = 0;
      // Tính toán
      if (calcuLength >= 2) {
        // 2,4,6,8
        total = calculating[calcuLength - 1].time - calculating[0].time;
        working = calculating.reduce(
          (subtotal, item, index) =>
            index % 2 !== 0 ? subtotal + item[count] : subtotal - item[count],
          0
        );
        breaking = total - working;
        // kiểm tra 30m break
        if (user && user.break && breaking < 1800) {
          breaking = 1800;
          working = total - 1800;
        }
      }
      // ======= ======= ======= ======= ======= ======= //
      // Kiểm tra độ dài của dữ liệu
      const length = data?.length || 0;
      const theRest = length > 0 && length < MAX_INPUT ? MAX_INPUT - length : 0;
      const emptyArr = theRest > 0 ? Array(theRest).fill(null) : [];
      const fullData = [...data, ...emptyArr]; // fill bằng null
      if (length >= maxRange) {
        maxRange = length;
      }
      // Gán ngày vào week data
      const username = user && user.username;
      const fullName = user && user.fullName;
      const stringDate = data && data[0] && data[0].stringDate;
      const stringDay = data && data[0] && data[0].stringDay;
      weekData = [
        ...weekData,
        {
          working,
          breaking,
          length,
          data,
          fullData,
          user,
          username,
          fullName,
          groupName,
          stringDate,
          stringDay,
        },
      ];
    });
    const workingTotal = weekData.reduce((time, item) => time + item.working, 0);
    const breakingTotal = weekData.reduce((time, item) => time + item.breaking, 0);
    return {
      workingTotal,
      breakingTotal,
      weekData,
      maxRange,
    };
  }
};

// Chuyển đổi dữ liệu sang dạng table
const convertTableData = (result, maxRange) => {
  const header = getHeaderByRange(maxRange);
  const body = getBodyByRange(maxRange, result);
  const workingSec = result && result.reduce((time, item) => time + item.workingTotal, 0);
  const breakingSec = result && result.reduce((time, item) => time + item.breakingTotal, 0);
  const finalWorking = workingSec && secondsToHoursAndMinuteFormat(workingSec);
  const finalBreaking = breakingSec && secondsToHoursAndMinuteFormat(breakingSec);
  const reportData = {
    header,
    body,
    workingSec,
    breakingSec,
    finalWorking,
    finalBreaking,
  };
  return reportData;
};

const getHeaderByRange = (range) => {
  let items = [];
  if (range) {
    for (let i = 0; i < range; i++) {
      const number = Math.round(i / 2) + (i % 2 === 0 ? 1 : 0);
      const id = i % 2 === 0 ? `in${number}` : `out${number}`;
      const label = i % 2 === 0 ? `In ${number}` : `Out ${number}`;
      const data = { id, label, csvLabel: label, align: "center", sortable: false };
      items = [...items, data];
    }
  }
  const preHeader = [
    { id: "username", label: "Username", csvLabel: "Username", align: "left", sortable: false },
    { id: "fullname", label: "Full Name", csvLabel: "Fullname", align: "left", sortable: true },
    { id: "group", label: "Group", csvLabel: "Group", align: "left", sortable: true },
    { id: "date", label: "Date", csvLabel: "Date", align: "center", sortable: false },
    { id: "day", label: "Day", csvLabel: "Day", align: "center", sortable: false },
  ];
  const endHeader = [
    {
      id: "workingtime",
      label: "Working Time",
      csvLabel: "Working Time",
      align: "center",
      sortable: false,
    },
    {
      id: "breakingtime",
      label: "Breaking Time",
      csvLabel: "Breaking Time",
      align: "center",
      sortable: false,
    },
  ];
  const header = [...preHeader, ...items, ...endHeader];
  return header;
};

const getBodyByRange = (range, result) => {
  let body = [];
  result &&
    result.forEach((r) => {
      const weekData = r.weekData;
      // Dữ liệu week
      weekData.forEach((dayData) => {
        const data = dayData.data;
        let items = [];
        for (let i = 0; i < range; i++) {
          let time = undefined;
          if (i < data.length) {
            time = data[i];
          }
          items = [...items, time];
        }
        const workingSec = dayData.working;
        const breakingSec = dayData.breaking;
        const working = secondsToHoursAndMinuteFormat(workingSec);
        const breaking = secondsToHoursAndMinuteFormat(breakingSec);
        const row = {
          ...dayData,
          type: "row",
          data: items,
          working,
          breaking,
          workingSec,
          breakingSec,
        };
        body = [...body, row];
      });
      // Gán dữ liệu total
      let totalData = [];
      for (let i = 0; i < range + 6; i++) {
        totalData = [...totalData, undefined];
      }
      const workingSec = r.workingTotal;
      const breakingSec = r.breakingTotal;
      const workingTotal = secondsToHoursAndMinuteFormat(workingSec);
      const breakingTotal = secondsToHoursAndMinuteFormat(breakingSec);
      totalData = [...totalData, workingTotal, breakingTotal];
      const total = {
        type: "total",
        data: totalData,
        workingSec,
        breakingSec,
      };
      body = [...body, total];
    });
  return body;
};

// Chuyến giây sang giờ phút
const secondsToHoursAndMinuteFormat = (seconds) => {
  if (seconds && seconds >= 0) {
    const time = moment.duration(seconds, "seconds").format("HH:mm", { trim: false });
    return time;
  }
  return "00:00";
};
