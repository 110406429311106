import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    CircularProgress
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase';
import { 
    firestoreGetUserList
} from  '../../../store/actions/firebase.action';

class UserDialogDelete extends Component {

  // ====================== Inits ====================== //
    
    constructor(props) {
        super(props);
        // state
        this.state = {
            error: '',
            loading: false,
        };
    }

    // ====================== Functions ====================== //

    // ====================== Firebase Functions ====================== //

    handleDeleteUser = () => {
        const { user } = this.props;
        this.setState({
            loading: true,
        }, () => {
          const userRef= firebase.firestore().collection('user');
          userRef.doc(user.id).delete().then(() => {
            this.props.firestoreGetUserList(() => {
                this.setState({
                    loading: false,
                }, () => {
                    this.props.handleConfirm();
                })
            });
          });
        })
    }

    // ====================== Render Component ====================== //


    renderDeleteButton = () =>{
        const { loading } = this.state;
        if (loading) {
          return (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className='add-btn'
              disabled
              startIcon={<CircularProgress size={15}/>}
            >
              Delete
            </Button>
          );
        }
        return (
          <Button 
            onClick={this.handleDeleteUser} 
            type="submit"
            variant="contained"
            className='add-btn'
            style={{ backgroundColor: 'red', color: '#fff' }}
          >
            Delete
          </Button>
        );
    }

    
    // ====================== Render Main ====================== //

    render() {
        const { error, loading } = this.state;
        const { dialogOpen, handleClose, user } = this.props;
        const fullname = (user && user.lastName && user.firstName) ? `${user.firstName} ${user.lastName}` : '';
        return (
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='sm'
                fullWidth
            >
            <DialogTitle id="scroll-dialog-title">Remove User {fullname}</DialogTitle>
            <DialogContent dividers >
              <p>Are you sure to delete "{fullname}". This action can't be undone.</p>
              <div className="red-text center">
                  { error ? <p>{error}</p> : null }
              </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={loading}>
                    Cancel
                </Button>
                {this.renderDeleteButton()}
            </DialogActions>
        </Dialog>
    );
  }
}

const mapDispatchToProps = {
    firestoreGetUserList
}
  
export default compose(connect(null,mapDispatchToProps))(UserDialogDelete)