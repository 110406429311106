import React from 'react';
import { connect } from 'react-redux';
import { BRAND_NAME } from '../../config/config';

const SignOutLink = (props) => {
    return (
        <div>
            <nav>
                <div className="nav-wrapper grey darken-3">
                    <div className="container">
                        <div className='brand-logo'>{BRAND_NAME}</div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

const mapStateToProps = (state) => ({
    setting: state.firebaseWeb.setting,
});


export default connect(mapStateToProps, {
})(SignOutLink);